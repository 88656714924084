/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { RxUtil } from "@/assets/util.js";
import { Radio, RadioGroup } from 'vue-ydui/dist/lib.rem/radio';
import sysuser from '@/components/common/SysUser';
import rxboxs from '@/components/common/rx-boxs';
import router from '@/router/index';
Vue.component(sysuser.name, sysuser);
Vue.component(rxboxs.name, rxboxs);
export default {
  data() {
    return {
      mailUrge: "1",
      loginUser: {},
      showUserDialog: false,
      selectUserList: [],
      data: {
        receiver: "",
        content: "",
        subject: "",
        senderId: "",
        userId: "",
        recIds: ""
      },
      //选中的的rx-boxs实例，用于传递数据。
      nodeUserVm: {},
      needCopySend: false,
      backFlag: ""
    };
  },
  methods: {
    getInfo() {
      var url = _baseUrl + '/mobile/oa/personalInfo/getUser.do';
      this.$ajax(url).then(response => {
        this.loginUser = response.data.user;
        this.data.senderId = this.loginUser.userId;
        this.data.userId = this.loginUser.userId;
      }).catch(error => {
        console.log(error);
      });
    },
    sendMail() {
      this.backFlag = "sendMail";
      let recIdsData = [];
      if (this.data.receiver != null) {
        if (this.data.receiver == '') {
          recIdsData = [];
        } else {
          recIdsData = JSON.parse(this.data.receiver);
        }
      }
      if (recIdsData.length == 0) {
        this.errorToast("请选择收件人");
        this.backFlag = "";
        return;
      }
      let recIdsArray = [];
      let recNamesArray = [];
      for (var i = 0; i < recIdsData.length; i++) {
        recIdsArray.push(recIdsData[i].id);
        recNamesArray.push(recIdsData[i].text);
      }
      this.data.recIds = recIdsArray.join(",");
      this.data.recNames = recNamesArray.join(",");
      var url = _baseUrl + '/mobile/oa/sendMail.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          data: this.data,
          urge: this.mailUrge
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("发送邮件成功", 1500);
          router.push({
            name: 'mailBox'
          });
        }
      }).catch(error => {
        console.log(error);
      });
    },
    /**
     * 打开用户对话框
     */
    selectUserDialog(vm) {
      var selectUser = vm.getIdVal(false);
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    /**
     * 点击用户对话框选择按钮，处理用户对话框返回数据。
     */
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    checkSender() {
      //alert("点击了发件人");
      this.needCopySend = true;
    },
    backToMailBox() {
      router.push({
        name: 'mailBox'
      });
    },
    //保存到草稿箱
    sendMailToDraft() {
      var url = _baseUrl + '/mobile/oa/saveInnerMailToDraftFolder.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          data: this.data,
          urge: this.mailUrge
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("已保存至草稿箱", 1500);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  created() {
    let reWriteMailData = this.$route.params.mailObject;
    if (reWriteMailData != null) {
      this.data = reWriteMailData;
    }
    this.getInfo();
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    if (this.backFlag === "sendMail") {
      next();
      return;
    }
    if (this.data.receiver != "" || this.data.content != "" || this.data.subject != "") {
      this.$dialog.confirm({
        title: '离开写邮件',
        mes: '已填写的邮件内容将丢失，或保存至草稿',
        opts: [{
          txt: '取消',
          color: '#177cb0'
        }, {
          txt: '保存到草稿箱',
          color: '#177cb0',
          callback: () => {
            this.sendMailToDraft();
            next();
          }
        }, {
          txt: '离开',
          color: 'red',
          callback: () => {
            next();
          }
        }]
      });
    } else {
      next();
    }
  }
};